.Quiz {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color:rgb(19, 58, 133);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 1em;
  color: white;
}

label {
  width: 100%;
  margin-top: 1em;
  text-align: start;
}

.Progress {
  height: 0.3em;
  border-radius: 0.2em;
  width: 380px;
  background-color: rgb(255, 255, 255);
}

.Bar {
  height: 100%;
  border-radius: 0.2em;
  transition: all ease 0.5s;
  background-color: green;
}

.custom-btn {
  width: 100%;
  padding: 0.5em 0;
  border-radius: 0.5em;
  border: 2px solid skyblue;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  transition: all ease 0.1s;
}

.custom-btn-active {
  border: 3px solid green;
  font-weight: bolder;
}

.res-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(87, 87, 87, 0.259);
  z-index: 3;
}

.Result {
  width: 300px;
  aspect-ratio: 1 / 1;
  background-color: white;
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
}

@media screen and (max-width: 767px) {
  .Quiz {
    width: 300px;
  }

  .Progress {
    width: 280px;
  }
}
